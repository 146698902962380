import React from "react";

const HeroBanner = () => {
  return (
    <div className="mt-20 flex items-center gap-x-6 bg-orange-400 px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
      <p className="text-sm leading-6 text-white">
        Ab jetzt können Sie wieder wie gewohnt Ihre THG-Quoten ganz einfach bei
        uns hochladen. &nbsp;
      </p>
      <div className="flex flex-1 justify-end" />
    </div>
  );
};

export default HeroBanner;
