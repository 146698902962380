import React from "react";
export const M1Icon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 60"
      x="0px"
      y="0px"
      width="85"
      height="85"
      className={className}
    >
      <title>001 - Sustainable Electric</title>{" "}
      <path d="M57,8a3,3,0,0,0,0-6H50a2,2,0,0,0-2-2H46a2,2,0,0,0-2,2H41a7,7,0,0,0-6.92,6H17A14.015,14.015,0,0,0,3,22V38.008a2.072,2.072,0,0,0-2,2.066v6.211a2.154,2.154,0,0,0-1,1.84v3.8A2.077,2.077,0,0,0,2.074,54H6.08a6.991,6.991,0,0,0,13.84,0H40.08a6.991,6.991,0,0,0,13.84,0h4.006A2.077,2.077,0,0,0,60,51.926v-3.87a2.069,2.069,0,0,0-1-1.781V41.5a3.158,3.158,0,0,0-2.957-3.135l-3.011-.1c-.011,0-.021-.006-.032-.006s-.015,0-.023,0L45.4,38.013,36.2,29.7a2.933,2.933,0,0,0-1.9-.7H15.91a2.923,2.923,0,0,0-2.3,1.151L7.511,38H5V22A12.013,12.013,0,0,1,17,10H34.08A7,7,0,0,0,41,16h3a2,2,0,0,0,2,2h2a2,2,0,0,0,2-2h7a3,3,0,0,0,0-6H50V8ZM13,58a5,5,0,1,1,5-5A5.006,5.006,0,0,1,13,58Zm34,0a5,5,0,1,1,5-5A5.006,5.006,0,0,1,47,58ZM57,41.5V42H55a1,1,0,0,1-1-1v-.7l1.939.064A1.145,1.145,0,0,1,57,41.5ZM34.88,31.206,42.4,38H23V31H34.3A.9.9,0,0,1,34.88,31.206Zm-19.693.177A.932.932,0,0,1,15.91,31H21v7H10.043ZM8,40H45l7,.232V41a3,3,0,0,0,3,3h2v2H56a1,1,0,0,0,0,2h1.751a.883.883,0,0,0,.249.056v3.87a.077.077,0,0,1-.074.074H53.92a6.991,6.991,0,0,0-13.84,0H19.92A6.991,6.991,0,0,0,6.08,52H2.074A.077.077,0,0,1,2,51.926v-3.8c0-.076.031-.121.009-.125H4a1,1,0,0,0,0-2H3V40.074A.077.077,0,0,1,3.074,40H8ZM57,4a1,1,0,0,1,0,2H50V4ZM48,16H46V11a1,1,0,0,0-2,0v3H41A5,5,0,0,1,41,4h3V7a1,1,0,0,0,2,0V2h2Zm9-4a1,1,0,0,1,0,2H50V12Z"></path>
      <path d="M13,50a3,3,0,1,0,3,3A3,3,0,0,0,13,50Zm0,4a1,1,0,1,1,1-1A1,1,0,0,1,13,54Z"></path>
      <path d="M47,50a3,3,0,1,0,3,3A3,3,0,0,0,47,50Zm0,4a1,1,0,1,1,1-1A1,1,0,0,1,47,54Z"></path>
      <path d="M22,48H39a1,1,0,0,0,0-2H22a1,1,0,0,0,0,2Z"></path>
    </svg>
  );
};
